<template>
  <Loading
    :active="loading.isLoading"
    :can-cancel="true"
    :is-full-page="loading.fullPage"
    color="#3E69F6"
  >
  </Loading>
</template>

<script>
import { AuthService } from "../../services/auth.service";

export default {
  data() {
    return {
      key: this.$route.query.key,
      currentQueries: window.location.href.includes("?")
        ? window.location.href.substring(window.location.href.indexOf("?"))
        : "",
      loading: {
        isLoading: true,
        fullPage: true,
      },
    };
  },
  created() {
    this.isVerified();
  },
  methods: {
    async isVerified() {
      try {
        const response = await AuthService.verify(this.key);
        if (response.code === "SUCCESS") {
          this.$router.push({
            path: `/home${this.currentQueries}`,
          });
        } else if (response.data.code === "INVALID_OR_EXPIRED") {
          this.$router.push({
            path: `/invalid-or-expired${this.currentQueries}`,
          });
        } else if (response.data.code === "EMAIL_ALREADY_VERIFIED") {
          this.$router.push({
            path: `/email-already-verified${this.currentQueries}`,
          });
        } else {
          alert(response.data.code);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading.isLoading = false;
      }
    },
  },
};
</script>
<style>
.bg-main {
  display: none;
}
</style>
